import React, { useEffect } from "react";
import moment from "moment";
import { navigate } from "gatsby";

export default function Day() {
	const currentDay = moment().add(1, "d").format("YYYY-MM-DD");

	useEffect(() => {
		navigate(`/calendar/${currentDay}`);
	}, []);

	return <></>;
}
